<template>
  <div class="guide-invoice">
    <ValidationObserver ref="form">
      <b-row>
        <b-col cols="12" lg="9">
          <b-card>
            <!--add header card to serialNum, Date-->
            <b-card-header>
              <div>
                <div class="d-flex mb-2 align-items-center">
                  <h3 class="text-primary pr-2">
                    <strong> E-LEARNING</strong>
                  </h3>
                </div>
              </div>
              <div>
                <ek-input-text
                  v-if="invoicid == 0"
                  readonly
                  name="number"
                  label="رقم الفاتورة"
                  :value="guideUnpaid.serialNumber"
                >
                </ek-input-text>
                <ek-input-text
                  v-if="invoicid != 0"
                  readonly
                  name="number"
                  label="رقم الفاتورة"
                  v-model="guideDto.serialNumber"
                >
                </ek-input-text>
                <ek-input-text
                  name="dateInvoice"
                  label="تاريخ الفاتورة"
                  :value="formDat(guideDto.dateCreated)"
                >
                </ek-input-text>
              </div>
            </b-card-header>
            <hr />
            <!--add Answers -->
            <b-card-body>
              <!--if no code Unpaid-->
              <div
                class="d-flex justify-content-center"
                v-if="guideUnpaid.answers == ''"
              >
                <h4>لا يوجد إجابات</h4>
              </div>

              <!--All Answer is Unpaid-->
              <div
                v-for="(ans, index) in guideUnpaid.answers"
                :key="index"
                class="d-flex"
              >
                <b-col lg="3" cols="3">
                  <b-form-group v-if="invoicid == 0" name="code" label="الجواب">
                    <b-form-input
                      name="code"
                      id="item-name"
                      type="text"
                      plaintext
                      v-model="ans.answer"
                    />
                  </b-form-group>
                </b-col>

                <b-col lg="2" cols="2">
                  <b-form-group
                    v-if="invoicid == 0"
                    name="date"
                    label="التاريخ"
                    label-for="date"
                  >
                    <b-form-input
                      name="date"
                      plaintext
                      :value="formDate(ans.date)"
                    />
                  </b-form-group>
                </b-col>

                <b-col lg="2" cols="2">
                  <b-form-group
                    v-if="invoicid == 0"
                    :disabled="!ready"
                    name="num"
                    label="رقم الدرس "
                  >
                    <b-form-input name="num" v-model="ans.orderLesson" />
                  </b-form-group>
                </b-col>

                <b-col lg="2" cols="2">
                  <b-form-group
                    v-if="invoicid == 0"
                    :disabled="!ready"
                    name="unit"
                    label="الوحدة"
                  >
                    <b-form-input name="unit" v-model="ans.unitName" />
                  </b-form-group>
                </b-col>

                <b-col lg="2" cols="2">
                  <b-form-group v-if="invoicid == 0" name="cost" label="الكلفة">
                    <b-form-input name="cost" plaintext v-model="ans.price" />
                  </b-form-group>
                </b-col>
                <b-col lg="1" cols="1" v-if="invoicid == 0">
                  <b-button variant="wight" @click="deleteAns(index)">
                    <svg
                      style="cursor: pointer"
                      width="25"
                      height="25"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                        stroke="#616060"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.875 7.125L7.125 11.875"
                        stroke="#616060"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.125 7.125L11.875 11.875"
                        stroke="#616060"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </b-button>
                </b-col>
              </div>

              <!--answer is paid-->
              <div v-for="ans in guideDto.answers" :key="ans.id" class="d-flex">
                <b-col lg="3" cols="3">
                  <b-form-group v-if="invoicid != 0" name="ans" label="الجواب">
                    <b-form-input
                      name="code"
                      id="item-name"
                      type="text"
                      plaintext
                      v-model="ans.answer"
                    />
                  </b-form-group>
                </b-col>

                <b-col lg="3" cols="3">
                  <b-form-group
                    v-if="invoicid != 0"
                    name="date"
                    label="التاريخ"
                    label-for="date"
                  >
                    <b-form-input
                      name="date"
                      plaintext
                      :value="formDate(ans.date)"
                    />
                  </b-form-group>
                </b-col>

                <b-col lg="2" cols="2">
                  <b-form-group
                    v-if="invoicid != 0"
                    :disabled="!ready"
                    name="num"
                    label="رقم الدرس"
                  >
                    <b-form-input name="num" v-model="ans.orderLesson" />
                  </b-form-group>
                </b-col>

                <b-col lg="3" cols="3">
                  <b-form-group
                    v-if="invoicid != 0"
                    :disabled="!ready"
                    name="unit"
                    label="الوحدة"
                  >
                    <b-form-input name="unit" v-model="ans.unitName" />
                  </b-form-group>
                </b-col>

                <b-col lg="2" cols="2">
                  <b-form-group v-if="invoicid != 0" name="cost" label="الكلفة">
                    <b-form-input name="cost" plaintext v-model="ans.price" />
                  </b-form-group>
                </b-col>
              </div>
            </b-card-body>
            <b-card-footer>
              <!--add footer to paid , note-->
              <b-row>
                <b-col lg="8" md="8">
                  <label>الملاحظات</label>
                  <ek-input-textarea
                    id="note"
                    rows="6"
                    v-model="guideDto.note"
                    name="note"
                    placeholder="اضف الملاحظات"
                  >
                  </ek-input-textarea>
                </b-col>
                <b-col lg="4" md="4">
                  <b-col cols="12">
                    <ul class="list-unstyled p-0 m-0">
                      <li
                        class="
                          d-flex
                          pb-1
                          pt-2
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div>عدد الإجابات</div>
                        <div v-if="invoicid == 0">
                          {{ guideUnpaid.answers.length }}
                        </div>
                        <div v-if="invoicid != 0">
                          {{ guideDto.countAnswers }}
                        </div>
                      </li>
                    </ul>
                  </b-col>
                  <b-col cols="12">
                    <hr class="d-none d-lg-block" />
                  </b-col>
                  <b-col cols="12">
                    <ul class="list-unstyled p-0">
                      <li
                        class="
                          d-flex
                          pb-1
                          pt-2
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div>المستحق للموظف</div>
                        <div v-if="invoicid == 0">
                          {{
                            guideUnpaid.answers.reduce((pre, cur) => pre + cur.price, 0)
                          }}
                        </div>
                        <div v-if="invoicid != 0">
                          {{ guideDto.guidePaid }}
                        </div>
                      </li>
                    </ul>
                    <div>
                      <b-input-group class="d-flex">
                        <b-input-group-prepend is-text>
                          المدفوع
                        </b-input-group-prepend>
                        <b-form-input
                          type="number"
                          v-model="guideDto.paid"
                          placeholder="المبلغ المدفوع"
                        >
                        </b-form-input>
                        <b-input-group-append is-text>
                          ل.س
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </b-col>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
        <b-col cols="12" lg="3" class="print">
          <!--add button to save , print-->
          <b-card>
            <b-button
              v-if="invoicid == 0"
              variant="primary"
              block
              @click="AddInvoice"
            >
              حفظ
            </b-button>
            <b-button
              v-if="invoicid == 0"
              variant="outline-primary"
              block
              @click="print"
            >
              طباعة
            </b-button>
            <b-button
              v-if="invoicid == 0"
              variant="outline-primary"
              block
              @click="$router.go(-1)"
              >عودة
            </b-button>
            <b-button
              v-if="invoicid != 0"
              variant="primary"
              block
              @click="print"
            >
              طباعة
            </b-button>
            <b-button
              v-if="invoicid != 0"
              variant="outline-primary"
              block
              @click="deleteInvoice(guideDto.id)"
            >
              حذف
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { BToast } from "bootstrap-vue";

export default {
  props: ["invoicid", "id"],
  computed: {
    ...mapState({
      guideUnpaid: (state) => state.invoiceGuide.guideUnpaid,
      guidesDetails: ({ invoiceGuide }) => invoiceGuide.guidesDetails,
    }),
  },
  data() {
    return {
      guideDto: {
        id: "",
        guideId: "",
        serialNumber: "",
        dateCreated: Date.now(),
        answers: [
          {
            id: "",
            answer: "",
            orderLesson: "",
            unitName: "",
            price: "",
            date: "",
          },
        ],
        answerIds: [],
        note: "",
        countAnswers: "",
        balanceDue: "",
        paid: "",
      },
      guideList: [],
    };
  },
  methods: {
    ...mapActions([
      "getGuideDetails",
      "getUnpaidGuideList",
      "addGuide",
      "deleteInvoice",
    ]),
    print() {
      window.print();
    },
    formDate(date) {
      let dte = `${new Date(date).toLocaleDateString()}`;
      return dte;
    },
    formDat(dateCreated) {
      let dte = `${new Date(dateCreated).toLocaleDateString()}`;
      return dte;
    },
    deleteAns(index) {
      this.guideUnpaid.splice(index, 1);
      this.guideDto.answerIds.splice(index, 1);
      this.guidDto.countAnswers--;
    },
    AddInvoice() {
      this.$refs.form.validate().then((succ) => {
        if (succ && this.guideUnpaid.length != 0) {
          this.addGuide(this.guideDto).then(this.guideList.push(this.guideDto));
        } else {
          const bToast = new BToast();
          bToast.$bvToast.toast(" لا يمكنك إضافة فاتورة ... لا يوجد إجابات", {
            title: "  لا يوجد إجابات ",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }
      });
    },
  },
  created() {
    this.guideDto.guideId = this.id;
    if (this.invoicid != 0) {
      this.getGuideDetails(this.invoicid).then((res) => {
        this.guideDto = { ...res };
      });
    } else {
      this.getUnpaidGuideList(this.id).then((res) => {
        this.guideDto.answerIds = res.answers.map((el) => el.id);
      });
    }
  },
};
</script>
<style >
@media print {
  .print {
    display: none;
  }
  .main-menu{
    display: none !important;
  }
  .app-content{
    margin: 0 !important;
  }
}
</style>